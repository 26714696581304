<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <a-input v-model:value="additionalSearchName" placeholder="Поиск по названию" @change="searchAdditional" allow-clear />
      </div>
      <div class="col-md-9 text-right">
        <vb-create-additional :modal="createModal" :key="createModalKey" :locales="locales" @reload="reload()" />
      </div>
    </div>
    <vb-edit-additional
      v-if="editModal"
      :reference-id="additionalId"
      :locales="locales"
      @reload="reload()"
      @hide-modal="hideEditModal()"
    />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
    >
      <template #name="{ record }">
        <div
          v-if="record.image && record.image !== ''"
          :style="getBackground(record.image)"
          class="record-icon"
        ></div>
        <div
          v-if="record.icon && record.icon !== ''"
          :style="getBackground(record.icon)"
          class="record-icon"
        ></div>
        <span class="record-name">
          <a-tree
            :selectable="false"
            :load-data="onLoadLocales"
            v-model:expandedKeys="expandedKeys"
            :tree-data="treeData.find(item => item.id === record.id)?.locales"
          >
            <template #switcherIcon><down-outlined /></template>
          </a-tree>
        </span>
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #edited="{ record }">
        <span v-if="record['updated_on']">
          {{ formattedDatetime(record['updated_on']) }}
        </span>
        <span v-if="record.edited">
          ({{ record.edited }})
        </span>
      </template>
      <template #favorite="{ record }">
        <a-tag
          @click="updateColumn(record['id'], record.name, 'favorite', 0)"
          v-if="record.favorite"
          color="#108ee9"
          class="cursor-pointer"
        >Да
        </a-tag>
        <a-tag
          @click="updateColumn(record['id'], record.name, 'favorite', 1)"
          class="cursor-pointer"
          v-else
        >Нет
        </a-tag>
      </template>
      <template #type="{ record }">
        <div v-if="editTypeId === record.id" class="row">
          <div class="col-md-9 px-0">
            <a-select
              :id="`type-${editTypeId}`"
              v-model:value="record.type"
              placeholder="Выберите тип"
              style="width: 100%"
              :filter-option="false"
              :options="typeFilter"
              @keydown.esc="editTypeId = 0"
              show-search
            >
            </a-select>
          </div>
          <div class="col-md-3 justify-content-end row p-0">
            <a-button
              @click="updateColumn(record.id, record.name, 'type', record.type); editTypeId = 0"
              type="text" shape="round" class="save-button">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else class="container-fluid">
          <div class="row">
            <a-select
              class="col-md-10"
              v-model:value="record.type"
              placeholder="Выберите тип"
              style="width: 100%"
              :filter-option="false"
              :options="typeFilter"
              disabled
              show-search
            >
            </a-select>
            <a-button @click="editTypeId = record.id" type="text" shape="round" class="px-2 pull-right col-md-2">
              <template #icon>
                <EditOutlined/>
              </template>
            </a-button>
          </div>
        </div>
      </template>
      <template #action="{ record }">
        <div class="text-right">
          <a-tooltip placement="top" class="mx-2" v-if="user.role !== 'manager'">
            <template #title>Редактировать</template>
            <a @click="showEditModal(record.id)"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2" v-if="user.role !== 'manager'">
            <template #title>Удалить</template>
            <a @click="deleteModal(record.id)"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
  </div>
</template>

<script setup>
import { watch, computed, reactive, ref, toRefs, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import VbCreateAdditional from "@/views/references/additional/create";
import VbEditAdditional from "@/views/references/additional/edit";
import apiClient from "@/services/axios";
import { useStore } from "vuex";
import {message, Modal} from "ant-design-vue";
import {DownOutlined, EditOutlined, SaveOutlined} from '@ant-design/icons-vue';

let
  editTypeId = ref(0),
  typeFilter = ref([
    {
      'label': 'Кухня и блюда',
      'value': 'cuisine',
    },
    {
      'label': 'Типы заведений',
      'value': 'classification',
    },
    {
      'label': 'Дополнительные',
      'value': 'additional',
    },
  ]),
  additionalSearchName = ref(''),
  dataSource = ref([]),
  loading = ref(false),
  createModal = ref(false),
  createModalKey = ref(0),
  confirmLoading = ref(false),
  editModal = ref(false),
  locales = ref([]),
  timer = null,
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  total = ref(0),
  additionalId = ref(0),
  treeData = ref([]);

const router = useRouter(),
  user = computed(() => useStore().getters['user/user']),
  searchInput = ref(),
  expandedKeys = ref([]),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    title: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'ID',
      key: 'key',
      dataIndex: 'key',
    },
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      slots: {
        customRender: 'name',
      },
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      slots: { customRender: 'type' },
    },
    {
      title: 'Избранный',
      dataIndex: 'favorite',
      sorter: true,
      key: 'favorite',
      slots: { customRender: 'favorite' },
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      sorter: true,
      key: 'added_on',
      slots: { customRender: 'date' },
    },
    {
      title: 'Изменен',
      dataIndex: 'updated_on',
      sorter: true,
      key: 'updated_on',
      slots: { customRender: 'edited' },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  onLoadLocales = treeNode => {
    return new Promise(resolve => {
      if (treeNode.dataRef.children) {
        resolve();
        return;
      }
      apiClient.get(`/filter/view?id=${treeNode.dataRef.key}`)
        .then(({ data }) => {
          if (data) {
            treeNode.dataRef.children = []
            data.data.locales.forEach((item) => {
              const model = locales.value.find(locale => locale?.short === item.locale)
              treeNode.dataRef.children.push({
                title: `${model.name}: ${item.title}`,
                key: item.id,
                children: [],
              })
            })
          }
        }).catch(err => {
        })
        .finally(() => {
          resolve()
        })

    });
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  searchAdditional = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ title: e.target.value })
    }, 800);
  },
  showEditModal = (id) => {
    additionalId.value = id
    editModal.value = true;
  },
  hideEditModal = () => {
    setTimeout(() => {
      editModal.value = false
    }, 100)
  },
  deleteModal = id => {
    Modal.confirm({
      title: 'Удаление записи',
      content: `Вы действительно хотите удалить запись?`,
      okText: 'Да',
      onOk() {
        return apiClient.delete(`filter/delete?id=${id}`).then(response => {
          if (response) {
            message.success('Запись успешно удалена')
          }
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          reload()
        });
      },
    });
  },
  updateColumn = (id, name, column = null, value = null) => {
    let data = {};
    data['name'] = name;
    if (column) {
      data[column] = value;
    }
    loading.value = true
    apiClient.put(`/filter/update?id=${id}`, data).then(response => {
      if (response) {
        message.success('Элемент успешно обновлен')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
      reload()
    });
  },
  formattedBalance = (balance) => {
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    let formattedBalance = formatter.format(balance / 100)
    return formattedBalance.substring(0, formattedBalance.length - 4)
  },
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get('/filter/index?type=additional', { params }).then(({data}) => {
      treeData.value = []
      data.data.items.forEach((item) => {
        treeData.value.push({
          id: item.id,
          locales: [{
            title: item.title,
            key: item.id,
          }],
        })
      })
      dataSource.value = data.data.items
      total.value = data.data.total
      locales.value = data.data.locales
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  getBackground = (url) => {
    return `background-image: url('${url}')`;
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  window.addEventListener('keydown', (e) => {
    if (e.key === 'Enter' && e.metaKey) {
      createModal.value = true
      createModalKey.value++
    }
  });
  getData({
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'additional',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>

<style scoped src="./index.css"></style>
